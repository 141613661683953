<template>
	<div class="upload-file">
		<el-upload :action="uploadFileUrl" :data="fileData" :before-upload="handleBeforeUpload" :file-list="fileList"
			:limit="limit" :on-error="handleUploadError" :on-exceed="handleExceed" :on-success="handleUploadSuccess"
			:show-file-list="false" :headers="headers" :on-progress="handleProgress" class="upload-file-uploader"
			ref="upload">
			<!-- 上传按钮 -->
			<el-button v-if="!isCustom" size="mini" type="primary">{{title}}</el-button>

			<slot name="custom"></slot>
			<!-- 上传提示 -->
			<div class="el-upload__tip" slot="tip" v-if="showTip">
				请上传
				<template v-if="fileSize"> 大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b> </template>
				<template v-if="fileType"> 格式为 <b style="color: #f56c6c">{{ fileType.join("/") }}</b> </template>
				的文件
			</div>
		</el-upload>

		<!-- 文件列表 -->
		<ul class="upload-file-list el-upload-list el-upload-list--text" name="el-fade-in-linear" tag="ul"
			v-if="showFile">
			<li :key="file.uid" class="el-upload-list__item ele-upload-list__item-content  px-30 boxSizing"
				v-for="(file, index) in fileList">
				<div class="flex-space-between w-100">
					<el-link :underline="false" target="_blank" @click.stop="previewFile(file.url)">
						<span class="el-icon-document fontColor-333 font15"> {{ file.name }} </span>
					</el-link>
					<div class="ele-upload-list__item-content-action">
						<el-link :underline="false" @click.stop="handleDelete(index)" type="danger">删除</el-link>
					</div>
				</div>
			</li>
		</ul>
		
		<pdfViewNew ref="pdfViews"></pdfViewNew>	
	</div>
</template>

<script>
	import {
		getToken
	} from "@/utils/auth";
	import base from '@/utils/base';
	export default {
		name: "FileUpload",
		props: {
			fileData: {
				type: Object,
				default: () => {
					return {
						type: 1
					}
				}
			},
			// 值
			value: [String, Object, Array],
			// 数量限制
			limit: {
				type: Number,
				default: 5000,
			},
			// 大小限制(MB)
			fileSize: {
				type: Number,
				default: 100,
			},
			// 文件类型, 例如['png', 'jpg', 'jpeg']
			fileType: {
				type: Array,
				default: () => ["doc", "xls", "ppt", "txt", "pdf", "png", "jpg", "jpeg"],
			},
			// 是否显示提示
			isShowTip: {
				type: Boolean,
				default: true
			},
			/* 上传的文字标题 */
			title: {
				type: String,
				default: '选取文件',
			},
			/* 是否显示上传后的文件内容 */
			showFile: {
				type: Boolean,
				default: true
			},
			isCustom: { //是否自定义
				type: Boolean,
				default: false
			},
			refresh: { //是否刷新
				type: Boolean,
				default: false
			},
			showAlert: { //是否显示上传成功提示
				type: Boolean,
				default: true
			},
		},
		data() {
			return {
				baseUrl: process.env.VUE_APP_BASE_URL,
				uploadFileUrl: process.env.VUE_APP_BASE_URL + "/file/upload", // 上传的图片服务器地址
				headers: {
					Authorization: "Bearer " + getToken(),
				},
				fileList: [],
			};
		},
		watch: {
			value: {
				handler(val) {
					if (val) {
						let temp = 1;
						// 首先将值转为数组
						const list = Array.isArray(val) ? val : this.value.split(',');
						// 然后将数组转为对象数组
						this.fileList = list.map(item => {
							if (typeof item === "string") {
								item = {
									name: item,
									url: item
								};
							}
							item.uid = item.uid || new Date().getTime() + temp++;
							return item;
						});
					} else {
						this.fileList = [];
						return [];
					}
				},
				deep: true,
				immediate: true
			},
			refresh: {
				handler(val) {
					if (val) {
						this.fileList = [];
					}
				},
				deep: true,
				immediate: true
			}
		},
		computed: {
			// 是否显示提示
			showTip() {
				return this.isShowTip && (this.fileType || this.fileSize);
			},
		},
		methods: {
			/* 预览文件 */
			 previewFile(keys) {
				this.$refs.pdfViews.getFileUrl(keys)
			},
			// 上传前校检格式和大小
			handleBeforeUpload(file) {
				// 校检文件类型
				if (this.fileType) {
					let fileExtension = "";
					if (file.name) {
						if (file.name.lastIndexOf(".") > -1) {
							fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
						}
					}
					const isTypeOk = this.fileType.some((type) => {
						if (file.type.indexOf(type) > -1) return true;
						if (fileExtension && fileExtension.indexOf(type) > -1) return true;
						return false;
					});
					if (!isTypeOk) {
						this.$message.error(`文件格式不正确, 请上传${this.fileType.join("/")}格式文件!`);
						return false;
					}
				}
				// 校检文件大小
				if (this.fileSize) {
					const isLt = file.size / 1024 / 1024 < this.fileSize;
					if (!isLt) {
						this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
						return false;
					}
				}
				return true;
			},
			// 文件个数超出
			handleExceed() {
				this.$message.error(`上传文件数量不能超过 ${this.limit} 个!`);

			},
			// 文件上传时
			handleProgress() {
				// this.$emit('loadOpen')
				this.$model.loading();
			},
			// 上传失败
			handleUploadError(err) {
				// this.$emit('loadClose')
				this.$message.error("上传失败, 请重试");
				this.$model.closeLoading();
			},
			// 上传成功回调
			handleUploadSuccess(res, file) {
				console.log("上传成功回调", res)
				if (res.code === 200) {
					if(this.showAlert){
						this.$message.success("上传成功");
					}
					this.$model.closeLoading();
					this.fileList.push({
						name: res.data.name,
						url: res.data.url
					});
					this.$forceUpdate()
					this.$emit("input", this.listToString(this.fileList));
					this.$emit("uploadSuccess", res.data);
					if (this.refresh) {
						this.fileList = []
					}
				} else {
					this.$message.error(res.msg);
					// this.loading.close();
					this.$model.closeLoading();
				}
				this.$emit('loadClose')
			},
			// 删除文件
			handleDelete(index) {
				this.fileList.splice(index, 1);
				this.$emit("input", this.listToString(this.fileList));
			},
			// 获取文件名称
			getFileName(name) {
				console.log("获取文件名称", name)
				if (name) {
					if (name.lastIndexOf("/") > -1) {
						return name.slice(name.lastIndexOf("/") + 1).toLowerCase();
					} else {
						return "";
					}
				}else{
					return ""
				}

			},
			// 对象转成指定字符串分隔
			listToString(list, separator) {
				let strs = "";
				separator = separator || ",";
				for (let i in list) {
					strs += list[i].url + separator;
				}
				return strs != "" ? strs.substr(0, strs.length - 1) : "";
			},
		},
	};
</script>

<style scoped lang="scss">
	.upload-file-uploader {
		margin-bottom: 5px;
	}

	.upload-file-list {
		// width: 50%;
	}

	.upload-file-list .el-upload-list__item {
		border: 1px solid #e4e7ed;
		line-height: 2;
		margin-bottom: 10px;
		position: relative;
		padding: 2px 0;
	}

	.upload-file-list .ele-upload-list__item-content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: inherit;
	}

	.ele-upload-list__item-content-action .el-link {
		margin-right: 10px;
	}
</style>