<template>
  <div>
	<el-dialog
	  top="2vh"
	  v-dialogDrag="maxScreen"
	  title="预览"
	  :visible.sync="visible"
	  width="1200px;"
	  @closed="close"
	  append-to-body
	>
	  <div 	v-if="['pdf'].includes($funs.fileLastSuffix(dialogSrc))">
		  <pdfViewer
		  		:src="dialogSrc"
		  		:style="{ height: height, width: '100%' }"
		  ></pdfViewer>
		 <!-- <cPdf :pdfurl="dialogSrc"></cPdf> -->
	  </div>
	  
	  
	  <vue-office-docx v-if="['docx','doc'].includes($funs.fileLastSuffix(dialogSrc))" :src="dialogSrc"
		:style="{ height: height, width: '100%' }"  />
		
	  <img v-if="['jpg','jpeg','png'].includes($funs.fileLastSuffix(dialogSrc)) && dialogSrc" :src="dialogSrc"
	  	alt="" :style="{'zoom':zooms,'width':'200px','height': '200px'}">
	  <div class="flex-box zoombox" v-if="['jpg','jpeg','png'].includes($funs.fileLastSuffix(dialogSrc))">
	  	<i class="el-icon-zoom-in fontColor-222 font30 pointer" title="放大" @click="zoomFun(1)"></i>
	  	<i class="el-icon-zoom-out ml-30 fontColor-222 font30 pointer" title="缩小" @click="zoomFun(2)"></i>
	  </div>
	  
		<div class="pdf flex-center" v-if="['mp4'].includes($funs.fileLastSuffix(dialogSrc))"
			style="height:50vh;border: 1px solid #ddd;background: #000;">
			<video id="myVideo" width="500" height="350" style="background-color: black;" controls>
				<source :src="dialogSrc" type="video/mp4">
				您的浏览器不支持播放视频.
			</video>
		</div>
	</el-dialog>
	
  </div>
</template>

<script>
// import cPdf from "./CPdf"; //pdf预览
	import {
		getFileUrl
	} from '@/api/common'
import pdf from 'vue-pdf'
import pdfViewer from './viewer'
import VueOfficeDocx from '@vue-office/docx'
export default {
  components: {
    pdf,
    pdfViewer,
	VueOfficeDocx,
	// cPdf
  },
  props: {
    srcList: {
      type: Array,
      default: () => {
        return []
      }
    },
    startPage: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      dialogSrc: '',
      visible: false,
      height: '700px',
	  zooms:1
    }
  },
  methods: {
	  //缩放
	  zoomFun(type) {
	  	if (type == 1) {
			if(this.zooms>1.5){
				return
			}
	  		this.zooms += 0.2;
			console.log("this.zooms",this.zooms);
	  	} else {
	  		this.zooms -= 0.2;
	  		if (this.zooms <= 1) {
	  			this.zooms = 1
	  		}
	  	}
	  },
	  /* 获取文件路径 */
	  getFileUrl(keys) {
		  this.dialogSrc =  "";
	  	if (!keys) {
	  		this.$message({
	  			title: '',
	  			message: '文件为空！',
	  			type: 'error'
	  		});
	  		return
	  	}
	  	if (keys.indexOf('http') != -1 || keys.indexOf('https') != -1) {
	  		this.dialogSrc = keys;
			this.visible = true;
	  		return
	  	}
	  	getFileUrl({
	  		key: keys
	  	}).then(res => {
	  		this.dialogSrc = res.data;
			this.visible = true;
	  	})
	  },
    click(src) {
      this.dialogSrc = src
      this.visible = true
    },
    close() {
      this.dialogSrc = '';
	  if (['mp4'].includes(this.$funs.fileLastSuffix(this.dialogSrc))) {
	  	var video = document.getElementById("myVideo");
	  	video.pause();
	  	video.currentTime = 0;
	  }
    },
    maxScreen(a) {
      if (a) {
        this.height = '500px'
      } else {
        this.height = window.innerHeight - 65 + 'px'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pdf {
  cursor: pointer;
}
// ::v-deep .el-dialog {
// 	height: 90vh !important;
// 	width: 90vw !important;
// }
::v-deep .el-dialog {
	height: 90vh !important;
	width: 90vw !important;
}
::v-deep .el-dialog__body{
	overflow: hidden;
	overflow-y: auto;
}
.zoombox {
	position: absolute;
	right: 10%;
	top: 50%;
	transform: translateY(-50%);
}
</style>